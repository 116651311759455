import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { rhythm } from '../utils/typography';
import { MDXProvider } from '@mdx-js/react';
import CodeBlock from '../components/CodeBlock';
import './Section.css';
import Tangent from '../components/Tangent';
import HighlightText from '../components/HighlightText';
import InlineCodeBlock from '../components/InlineCodeBlock';
import { getActiveClasses } from 'get-active-classes';

const components = {
  pre: props => <div {...props} />,
  code: props => <CodeBlock {...props} />,
  inlineCode: props => <InlineCodeBlock {...props} />,
  Tangent,
  HighlightText,
};

export default function Section({ section, className }) {
  return (
    <article
      className={getActiveClasses(className, 'px-8 sm:pl-0 sm:pr-8 pt-8')}
    >
      <h1
        className="mt-0 text-4xl mb-6 font-header"
        id={`section-${section.frontmatter.section}`}
      >
        {section.frontmatter.title}
      </h1>
      {!section.frontmatter.incomplete && (
        <MDXProvider components={components}>
          <MDXRenderer>{section.body}</MDXRenderer>
        </MDXProvider>
      )}
      {section.frontmatter.incomplete && (
        <span>I'm still writing this section...</span>
      )}
    </article>
  );
}

import React from 'react'
import Highlight, {defaultProps} from 'prism-react-renderer'
import dracula from 'prism-react-renderer/themes/duotoneLight';
import { getActiveClasses } from 'get-active-classes';

export default ({children, className = ""}) => {
  // return <>{children}</>
  const language = className.replace(/language-/, 'jsx')
  
  // TODO replace hard coded js highlight?
  return (
    <Highlight {...defaultProps} theme={dracula} code={children} language={"js"}>
      {({className, style, tokens, getLineProps, getTokenProps}) => (
        <pre className={getActiveClasses(className, "text-lg rounded-lg inline")} style={{...style}}>
          {tokens.map((line, i) => (
            <span key={i} {...getLineProps({line, key: i})}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({token, key})} />
              ))}
            </span>
          ))}
        </pre>
      )}
    </Highlight>
  )
}
import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';

class BlogPostTemplate extends React.Component {
  render() {
    const section = this.props.pageContext.section;

    return (
      <Layout location={this.props.location} title={section.frontmatter.title}>
        <Section className="mx-auto" section={section} />
      </Layout>
    );
  }
}

export default BlogPostTemplate;
